/* src/styles/HotItems.css */

.hot-items {
  padding: 20px;
  background-color: #222;
  border-radius: 8px;
}

.section-header {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
}

.items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Increased gap for better spacing */
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33.333% - 20px); /* Adjust width to account for gap */
  max-width: 465px;
}

.item-category {
  font-size: 1.5em;
  font-weight: bold;
  color: #a1d293; /* Use your accent color */
  margin-bottom: 10px;
  text-align: center;
}

.item-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  padding: 0;
  background: transparent;
  width: 100%;
  cursor: pointer;
}

.item-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  transition: transform 0.3s;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

.item-button:hover,
.item-button:hover .item-name {
  color: inherit !important;
  background-color: transparent !important;
  transform: scale(1.10);
}

.item-name {
  margin-top: 10px;
  font-size: 2.5em;
  color: #ffffff;
  width: 100%;
  text-align: center;
  direction: rtl; /* Ensure right-to-left text direction */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  position: relative;
}

.cta-overlay {
  position: absolute;
  top: 10px; /* Adjust to position above the image */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 1.2em; /* Adjust font size as needed */
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .item {
      width: calc(50% - 20px); /* 2 items per row */
  }
}

@media (max-width: 768px) {
  .item {
      width: 100%; /* 1 item per row */
  }

  .item-image {
      width: 85%;
  }

  .item-name {
      font-size: 2em; /* Slightly smaller font on mobile */
  }

  .item-category {
      font-size: 1.3em;
  }
}

@media (max-width: 480px) {
  .section-header {
      font-size: 1.5em;
  }

  .item-name {
      font-size: 1.8em;
  }

  .item-category {
      font-size: 1.2em;
  }
}