/* src/styles/FloatingCartButton.module.css */
.floatingCartButton {
    position: fixed;
    left: 20px;
    bottom: 20px;
    background-color: #28a745;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
  
  .cartIcon {
    width: 30px;
    height: 30px;
    filter: invert(1);
  }
  
  .cartCount {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #ff4500;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: bold;
  }