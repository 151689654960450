/* src/styles/Hero.css */
.hero {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.hero-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
}

.hero-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.hero-content {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    color: white;
    z-index: 1;
    max-width: 80%;
}

.hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.hero p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.cta-button {
    display: inline-block;
    background-color: #e2732e;
    color: white;
    padding: 1rem 2rem;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #c96629;
}

@media (max-width: 1024px) {
    .hero h1 {
        font-size: 2.2rem;
    }

    .hero p {
        font-size: 1.1rem;
    }
}

@media (max-width: 768px) {
    .hero {
        height: auto;
        min-height: 60vh;
        margin-top: 0;
    }

    .hero-content {
        max-width: 90%;
        padding: 1.5rem;
        margin: 2rem 1rem;
    }

    .hero h1 {
        font-size: 2rem;
    }

    .hero p {
        font-size: 1rem;
    }

    .cta-button {
        padding: 0.8rem 1.6rem;
    }
}

@media (max-width: 480px) {
    .hero {
        min-height: 50vh;
    }

    .hero-content {
        max-width: 95%;
        padding: 1rem;
    }

    .hero h1 {
        font-size: 1.8rem;
        margin-bottom: 0.5rem;
    }

    .hero p {
        font-size: 0.9rem;
        margin-bottom: 1.5rem;
    }

    .cta-button {
        padding: 0.7rem 1.4rem;
        font-size: 0.9rem;
    }
}