/* Footer Styling */
.footer {
    display: flex;
    justify-content: center; /* Center the footer content horizontally */
    align-items: center; /* Align items vertically */
    background-color: #333;
    padding: 20px;
    color: #fff;
    direction: rtl; /* Set text direction to right-to-left */
    font-size: 25px;
}

.footer-content {
    display: flex;
    justify-content: center; /* Align items to the left within the footer */
    width: 100%; /* Full width of the footer container */
    max-width: 1200px; /* Adjust max-width to your preference */
}

.footer-section {
    margin: 0 20px; /* Adjust margin as needed */
    text-align: right;
}

.footer-section img {
    max-width: 250px; /* Adjust the size of the logo */
}

.footer-logo {
    margin-left: 60px; /* Increase the margin to create more space */
}

.footer-header {
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 10px;
}

.footer-links ul {
    list-style-type: none;
    padding: 0;
}

.footer-links ul li {
    margin-bottom: 5px;
}

.footer-links ul li a {
    color: #ffbd5b; /* Default color */
    text-decoration: none;
}

.footer-links ul li a:hover {
    text-decoration: underline; /* Add underline on hover */
}

.footer-contact {
    display: flex;
    flex-direction: column;
    align-items: right;
    margin-left: 60px;
}

.footer-contact a {
    color: #ffbd5b; /* Same bright orange color as links */
    text-decoration: none; /* Remove underline for consistency */
}

.footer-contact .contact-heading {
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 5px; /* Adjust spacing between the heading and the content */
}

.footer-contact .contact-hours {
    font-weight: normal; /* Normal weight for hours */
    margin-top: 0; /* Remove top margin */
}

.footer-contact a:hover {
    text-decoration: underline; /* Add underline on hover */
}

.footer-contact p {
    margin: 5px 0; /* Reduce the space between paragraphs */
    line-height: 1.4; /* Adjust line height for better spacing */
}

@media (max-width: 800px) {
    .footer-content {
        flex-direction: row; /* Stack sections vertically on small screens */
        align-items: right;
    }

    .footer-contact{
        margin-left: 60px;
    }

    .footer-section {
        margin: 10px 10px; /* Adjust margin for mobile */
        font-size: 20px;
    }

    .footer-section img {
        margin-left: 30px;
        max-width: 200px; /* Adjust the size of the logo */
    }
}

@media (max-width: 600px) {
    .footer-section {
        margin: 10px 0; /* Adjust margin for mobile */
        font-size: 15px;
    }

    .footer-section img {
        margin-left: 20px;
        max-width: 150px; /* Adjust the size of the logo */
    }

    .footer-contact{
        margin-left: 10px;
    }
}

@media (max-width: 450px) {
    .footer {
        font-size: 14px; /* Reduce the base font size */
    }

    .footer-section {
        margin: 5px 0; /* Further reduce margins */
        font-size: 12px; /* Make text even smaller */
    }

    .footer-section img {
        max-width: 100px; /* Further reduce logo size */
        margin-left: 10px; /* Adjust margin */
    }

    .footer-contact {
        margin-left: 5px; /* Reduce left margin */
    }

    .footer-header {
        font-size: 14px; /* Adjust header size */
    }

    .footer-links ul li {
        margin-bottom: 3px; /* Reduce space between list items */
    }

    .footer-contact p {
        margin: 3px 0; /* Reduce space between paragraphs */
        line-height: 1.3; /* Slightly reduce line height */
    }
}