/* src/styles/PaymentSuccessPage.css */

.payment-success-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    background-color: #333;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  h1 {
    color: #4CAF50;
    margin-bottom: 20px;
  }
  
  .success-message {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .back-to-home-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .back-to-home-button:hover {
    background-color: #45a049;
  }

  body {
    direction: rtl;
  }