/* src/styles/AddToCartMessage.css */
.add-to-cart-message {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
    animation: fadeInOut 3s ease-in-out;
    font-size: 30px;
  }
  
  @keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    10%, 90% { opacity: 1; }
  }

  @media (max-width: 650px) {
    .add-to-cart-message {
        font-size: 20px;
    }
  }

  @media (max-width: 450px) {
    .add-to-cart-message {
        font-size: 15px;
    }
  }