.product {
  border: 1px solid #010101;
  border-radius: 8px;
  padding: 16px;
  margin: 0;
  background-color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center align the main content */
  direction: rtl; /* Set text direction to right-to-left */
}

.product-image {
  width: 100%;
  height: auto;
  max-width: 300px;
  border-radius: 8px;
  margin-bottom: 16px;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

.product h3 {
  margin: 0 0 8px;
  font-size: 1.2em;
  color: #FFF;
}

.nutrition-values {
  margin: 10px 0;
  font-size: 0.9em;
}

.nutrition-values ul {
  list-style-type: none;
  padding: 0;
}

.nutrition-values li {
  display: inline-block;
  margin-right: 10px;
}

.product-price {
  margin: 8px 0;
  font-size: 1em;
  color: #FFF;
}

button {
  background-color: #FFA500;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 8px 0;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #e59400;
}

.toggle-options {
  background-color: #0b529d;
}

.toggle-options:hover {
  background-color: #0056b3;
}

.dropdown {
  margin: 16px 0;
  text-align: right; /* Align dropdown text to the right */
}

.dropdown label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.dropdown select,
.dropdown input {
  margin-bottom: 8px;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ccc;
  text-align: right; /* Align input text to the right */
  background-color: #857f7f;
  color: #FFF;
}

.total-price {
  margin: 8px 0;
  font-weight: bold;
  font-size: 1.1em;
}

.add-to-cart {
  background-color: #28a745;
}

.add-to-cart:hover {
  background-color: #218838;
}