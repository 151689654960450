.phoneContainer {
    position: relative;
  }
  
  .phoneNumber {
    text-decoration: none;
    color: #ffbd5b;
    font-weight: bold;
    font-size: 1.8em;
    border-bottom: 2px solid #ffbd5b;
  }
  
  .tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #333;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    z-index: 1000;
  }

  body{
    text-decoration: rtl;
  }

  @media (max-width: 640px) {
    .phoneNumber {
      font-size: 1.3em;
    }
  }
  @media (max-width: 550px) {
    .phoneNumber {
      font-size: 1.3em;
    }
  }
  /* Media query for screens 450px and under */
@media (max-width: 452px) {
  .phoneNumber {
    font-size: 1.2em;
  }
}
    /* Media query for screens 450px and under */
@media (max-width: 430px) {
  .phoneNumber {
    font-size: 0.9em;
  }
}