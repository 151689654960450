.store-container {
    padding: 16px; /* Optional padding around the container */
}

.product-category {
    margin-bottom: 32px; /* Space between categories */
    text-align: center; /* Center the category header */
}

.product-category h2 {
    font-size: 2em; /* Adjust header size */
    margin-bottom: 16px; /* Space below header */
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 items per row */
    gap: 16px; /* Space between products */
    justify-content: center; /* Center items horizontally */
}

.product-card {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    background-color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box; /* Include padding and border in width */
}

.product-image {
    width: 100%;
    height: auto;
    max-width: 300px; /* Increase to make images larger */
    border-radius: 8px;
    margin-bottom: 16px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .product-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 items per row */
    }
}

@media (max-width: 480px) {
    .product-grid {
        grid-template-columns: 1fr; /* 1 item per row */
    }
}

html {
    scroll-behavior: smooth;
  }
  
  .product-category {
    scroll-margin-top: 100px; /* Adjust this value based on your header height */
  }
  