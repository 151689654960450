/* src/pages/About.css */
.about-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  direction: rtl; /* Set text direction to right-to-left */
}
  
h1 {
  margin-top: 10px;
  font-size: 35px;
  color: #FFA500; /* Bright orange color to match the theme */
  margin-bottom: 15px;
  text-align: center; /* Align headers to the right for RTL */
}

p {
  margin-bottom: 15px; /* Space between paragraphs */
  line-height: 1.6; /* Improve readability */
}
 
  .about-container p {
    font-size: 1.4em;
    line-height: 1.5;
  }

  @media (max-width: 1227px) {
    .floating-button {
      top: 110px;
    }
  }

 @media (max-width: 768px) {
  .floating-button {
    top: 70px;
  }
}

.how-it-works {
  margin-top: 80px;
}

.how-it-works-header {
  margin-bottom: 60px;
  font-size: 35px;
  color: #FFA500; /* Bright orange color to match the theme */
  text-align: center; /* Align headers to the right for RTL */
}

.diagram {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: flex-start; /* Align items to the start */
}


.diagram-item {
  margin-right: 20px; /* Space between items */
  min-width: 200px;
  text-align: cent; /* Right align text for RTL */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 17px;
}

.diagram-text h3 {
  font-size: 25px; /* Adjust as needed */
  font-weight: bold;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  line-height: 1.2; /* Control line height for consistency */
}

.diagram-item img {
  max-width: 135px; /* Adjust image size */
  margin-bottom: 10px;
  margin-left: 50px;
}

.diagram-text {
  margin-bottom: 10px;
}

.arrow-icon {
  font-size: 50px;
  color: #67bbff; /* Arrow color */
  margin: 0 10px; /* Adjust margin between items */
}

.diagram-item:last-child .arrow-icon {
  display: none; /* Hide arrow for the last item */
}

/* Media query for screens smaller than 450px */
@media (max-width: 800px) {
  .diagram {
    flex-direction: column;
    align-items: center;
  }

  .diagram-item {
    margin-bottom: -30px;
    margin-right: 0; /* Remove right margin */
  }

  .arrow-icon {
    font-size: 40px; /* Reduce arrow size */
    margin: 50px 0; /* Add margin above and below arrows */
    transform: rotate(270deg)
  }
}

/* Section for "Our Values" */
.our-values {
  margin-top: 80px;
}

.our-values-header {
  margin-bottom: 60px;
  font-size: 35px;
  color: #FFA500; /* Bright orange color to match the theme */
  text-align: center; /* Align headers to the right for RTL */
}

.values-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the left */
}

.value-item {
  display: flex;
  align-items: center; /* Center items vertically */
  margin-bottom: 20px; /* Space between items */
  text-align: right; /* Right align text for RTL */
  width: 100%; /* Make items take full width */
  font-size: 20px;
}

.value-item img {
  max-width: 80px; /* Adjust image size */
  margin-left: 20px; /* Space between image and text */
}

.value-text {
  max-width: 600px; /* Adjust text width as needed */
}

@media (max-width: 600px) {
  .value-item {
    flex-direction: column; /* Stack image and text vertically on small screens */
    align-items: center; /* Center items horizontally */
  }

  .value-item img {
    margin-left: 0; /* Remove left margin on small screens */
    margin-bottom: 10px; /* Space between image and text */
  }
}

/* FAQPage.css */
.faq-container {
  margin-top: 60px;
}

.faq-header {
  margin-bottom: 40px;
  font-size: 35px;
  color: #FFA500; /* Bright orange color to match the theme */
  text-align: center; /* Center align headers for the FAQ page */
}

.faq-item {
  margin-bottom: 20px;
  font-size: 20px;
}

.faq-question {
  cursor: pointer;
  font-size: 25px;
  color: #FFF;
  display: flex;
  align-items: center;
}

.faq-question svg {
  margin-right: 10px;
}

.faq-answer {
  margin: 5px 0;
  font-size: 16px;
  color: #FFF;
}