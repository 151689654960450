/* Reviews Section Styling */
.reviews {
    padding: 20px;
    background-color: #222;
    margin: 0 auto;
    max-width: 1600px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.section-header {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
}

.reviews-slider {
    position: relative;
    width: 100%;
}

.reviews-slider img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    max-width: 300px;
    margin: 0 auto;
    display: block;
}

.custom-swiper-button {
    position: absolute;
    top: 49%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    z-index: 10; /* This ensures the buttons are above the slides */
}

.custom-swiper-button-prev {
    left: -20px; /* Move slightly inward */
}

.custom-swiper-button-next {
    right: -20px; /* Move slightly inward */
}

.custom-swiper-button img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.custom-swiper-button:hover {
    background-color: transparent !important;
}