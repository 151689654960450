/* src/styles/Header.module.css */
.headerContainer {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: #ffffff;
  top: 0;
}

.headerLeft, .headerMiddle, .headerRight {
  display: flex;
  align-items: center;
  flex: 1;
}

.headerLeft {
  justify-content: flex-start;
}

.headerMiddle {
  justify-content: center;
}

.headerRight {
  justify-content: flex-end;
}

.logo {
  height: 70px; /* Increased size */
  width: auto;
}

.socialIcon {
  width: 45px; /* Increased size */
  height: 45px; /* Increased size */
  margin: 0 7.5px; /* Adjusted margin */
}

.phoneNumber {
  text-decoration: none;
  color: #ffbd5b;
  font-weight: bold;
  font-size: 1.8em;
  border-bottom: 2px solid #ffbd5b;
}

.phoneNumber:hover {
  text-decoration: none;
}

.navSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  border-bottom: 1px solid #3a3a3a;
  position: sticky;
  top: 0; /* Adjust this value based on your header height */
}

.navButtons {
  display: flex;
  justify-content: flex-start;
}

.navButton, .orderButton, .cartButton {
  margin: 0 7.5px; /* Adjusted margin */
  padding: 8px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  font-size: 23px;
}

.navButton {
  background-color: #333;
  color: #ffffff;
}

.navButton:hover {
  background-color: #444;
}

.orderButton, .cartButton {
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.orderButton {
  background-color: #e2732e;
}

.cartButton {
  background-color: #28a745;
}

.orderButton:hover {
  background-color: #c96629;
} 

.cartButton:hover {
  background-color: #1f8938;
  text-decoration: none;
}

.cartButtonContainer {
  margin-right: auto; /* Push to the left */
}

.cartIcon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  filter: invert(1);
}

.cartCount {
  background-color: #ff4500;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8em;
  margin: 0 5px;
}

/* Ensure right-to-left text direction */
.header, .navSection, .cartButton, .navButtons {
  direction: rtl;
}


/* Media query for screens 768px and under */
@media (max-width: 768px) {
  .header {
    padding: 10px 20px;
  }

  .logo {
    height: 45px;
  }

  .socialIcon {
    width: 28px;
    height: 28px;
    margin: 0 5px;
  }

  .phoneNumber {
    font-size: 1.4em;
  }

  .navSection {
    padding: 10px 20px;
  }

  .navButton, .orderButton, .cartButton {
    padding: 8px 15px;
    font-size: 1em;
    margin: 0 5px;
  }

  .cartIcon {
    width: 28px;
    height: 28px;
    margin-right: 5px;
  }

  .cartCount {
    padding: 2px 6px;
    font-size: 0.9em;
    margin: 0 5px;
  }
}

/* Media query for screens 450px and under */
@media (max-width: 500px) {
  .header {
    flex-direction: row;
    align-items: center;
    padding: 10px;
  }

  .headerLeft, .headerMiddle, .headerRight {
    margin-bottom: 10px;
  }

  .logo {
    height: 35px;
  }

  .socialIcon {
    width: 24px;
    height: 24px;
    margin: 0 3px;
  }

  .phoneNumber {
    font-size: 0.2em;
  }

  .navSection {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .navButtons {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
  }

  .cartButtonContainer {
    margin-right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .cartButton {
    justify-content: center;
    width: auto;
  }

  .cartIcon {
    width: 24px;
    height: 24px;
    margin-right: 3px;
  }

  .cartCount {
    padding: 1px 5px;
    font-size: 0.8em;
    margin: 0 3px;
  }
}

@media (max-width: 435px) {
  .cartButton {
    padding: 8px 15px;
    font-size: 1em;
  }
  .orderButton, .navButton{
    font-size: 1em;
    margin: 0 3px;
    padding: 8px 4px;
  }
}