/* CartPage.css */

.cart-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  direction: rtl; /* For RTL layout */
}

.empty-cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  text-align: center;
}

.empty-cart h1 {
  margin-bottom: 20px;
}

.empty-cart-message {
  font-size: 1.2em;
  max-width: 80%;
  direction: rtl;
  text-align: center;
  line-height: 1.5;
  color: #ffffff;
}

@media (max-width: 768px) {
  .empty-cart-message {
    font-size: 1em;
  }
}
.cart-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}

.cart-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  border: 1px solid #ccccccad;
  padding: 10px;
  border-radius: 10px;
  background-color: #333;
}

.cart-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-left: 20px; /* Changed from margin-right for RTL */
  border-radius: 8px;
}

.cart-item-details {
  flex: 1;
  color: #fff;
}

.cart-item-details h3 {
  margin: 0 0 10px 0;
}

.cart-item-details p {
  margin: 5px 0;
}

.cart-item-details button,
.edit-item-form button {
  margin-left: 10px; /* Changed from margin-right for RTL */
  padding: 5px 10px;
  background-color: #ff8138;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cart-item-details button:hover,
.edit-item-form button:hover {
  background-color: #ff81388a;
}

.edit-item-form {
  background-color: #444;
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
}

.edit-item-form label {
  display: block;
  margin-bottom: 10px;
  color: #fff;
}

.edit-item-form select,
.edit-item-form input {
  padding: 5px;
  margin-right: 10px; /* Changed from margin-left for RTL */
  background-color: #555;
  color: #ffffff;
  border: 1px solid #777;
  border-radius: 3px;
}

.cart-summary {
  margin-top: 20px;
  text-align: center; /* Changed from right for RTL */
  font-weight: bold;
  font-size: 1.2em;
  color: #fff;
}

.proceed-to-payment {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1em;
  margin-top: 10px;
}

.proceed-to-payment:hover {
  background-color: #218838;
}

.clear-cart {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1em;
  margin-top: 20px;
}

.clear-cart:hover {
  background-color: #c82333;
}

/* For screens smaller than 600px */
@media (max-width: 600px) {
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item-image {
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .cart-item-details {
    width: 100%;
  }
}