body {
  background-color: #222;  /* Dark background for the entire page */
  color: #f8f8f8;  /* Light text color for visibility */
  font-family: 'Rubik', sans-serif ,Arial;
  margin: 0;
  padding: 0;
  direction: rtl;  /* For right-to-left text */
}

a {
  color: #1DA1F2;  /* Ensure links are visible with a light blue color */
  text-decoration: none;
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
* {
  scrollbar-color: #888 #3e3b3b;
}

/* You can add more specific font styles here */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;  /* Bold for headings */
}

p, span, div {
  font-weight: 400;  /* Regular for body text */
}

.light-text {
  font-weight: 300;  /* Light weight for specific elements */
}