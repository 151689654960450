/* src/styles/BriefAboutUs.css */
.brief-about-us {
    background-color: #26b8cc;
    padding: 2rem;
    text-align: center;
    max-width: 800px;
    margin: 6rem auto;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.brief-about-us h2 {
    color: #111;
    margin-bottom: 0.5rem;
    font-size: 3rem;
    border-bottom: 2px solid #111;
    padding-bottom: 0.0rem;
    display: inline-block;
}

.brief-about-us p {
    color: #111;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
}

.learn-more-button {
    display: inline-block;
    background-color: #e2732e;
    color: white;
    padding: 0.5rem 1rem;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.learn-more-button:hover {
    background-color: #c96629;
}

@media (max-width: 900px) {
    .brief-about-us {
        margin: 2rem 2rem;
        padding: 0.8rem;
    }

    .brief-about-us h2 {
        font-size: 2.5rem;
    }

    .brief-about-us p {
        font-size: 1.51rem;
    }
}

@media (max-width: 480px) {
    .brief-about-us {
        margin: 2rem 1.5rem;
        padding: 1rem;
    }

    .brief-about-us h2 {
        font-size: 2rem;
    }

    .brief-about-us p {
        font-size: 1.3rem;
    }
}