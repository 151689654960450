/* src/styles/CheckoutPage.css */

.checkout-page {
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #444;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  direction: rtl;
}

.checkout-form {
  width: 55%;
  background-color: #333;
  border-radius: 8px;
  padding: 1rem 3rem 1rem 3rem;
  box-shadow: 0 0 10px #ffffff9d;
  margin-left: 2rem;
}

.order-summary {
  width: 40%;
  background-color: #333;
  padding: 1rem 3rem 1rem 3rem;
  border-radius: 8px;
  box-shadow: 0 0 10px #ffffff9d;
}

.form-fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.validation-message {
  position: absolute;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8em;
  margin-top: 5px;
}

.exclamation-mark {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #838723;
  color: white;
  text-align: center;
  border-radius: 50%;
  margin-right: 5px;
}

.form-group {
  width: 48%; /* Reduced from 48% to 45% */
  margin-bottom: 2.2rem;
}

.form-group input {
  width: 90%; /* This will make the input fields take up 90% of their container width */
}

.form-group.error input {
  border-color: #f5c6cb;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}

@media (max-width: 1134px) {
  .checkout-page {
      flex-direction: column;
      padding: 1rem 1rem 1rem 0.1rem; /* Further reduced left padding */
      margin: 1rem 1rem 1rem 1rem; /* Further reduced left margin */
      max-width: calc(100% - 0.0rem); /* Adjusted max-width */
  }

  .checkout-form,
  .order-summary {
      width: 91%;
      margin: 0.5rem 1rem 2rem 1rem; /* Further reduced left margin */
      padding: 1rem 1.5rem; /* Further reduced left padding */
  }
}

@media (max-width: 1024px) {
  .checkout-page {
    margin: 0.5rem;
    padding: 0.5rem;
    max-width: calc(100% - 1rem);
  }

  .checkout-form,
  .order-summary {
    width: 100%;
    margin: 0.5rem 0;
    padding: 0rem;
  }

  .form-group {
    width: 100%;
  }

  .form-group input {
    width: 100%;
    box-sizing: border-box;
  }

  .form-fields {
    padding: 0 1rem;
  }

  h2 {
    margin-right: 1rem;
  }

  .clear-button{
    margin-right: 1rem;
  }
}

@media (max-width: 768px) {
  .checkout-page {
    margin: 0.25rem;
    padding: 0.25rem;
  }

  .checkout-form,
  .order-summary {
    padding: 0rem;
  }

  .form-fields {
    padding: 0 0.5rem;
  }

  h2 {
    margin-right: 0.5rem;
  }
}

@media (max-width: 450px) {
  .checkout-page {
    margin: 0.1rem;
    padding: 0.1rem;
  }

  .checkout-form,
  .order-summary {
    padding: 0.5rem;
  }

  .form-group input {
    width: 100%;
    font-size: 0.9em;
  }

  .form-fields {
    padding: 0 0.25rem;
  }

  h2 {
    margin-right: 0.25rem;
    font-size: 1.2em;
  }
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #FFF;
  font-weight: 600;
}

input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #999999;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s;
  background-color: rgb(100, 76, 76);
  color: #FFF;
}

input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.continue-button {
  display: block;
  width: 200px;
  margin: 1.5rem auto;
  background-color: #5da649;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.continue-button:hover {
  background-color: #4cae4fb3;
}

.edit-button {
  display: block;
  width: 200px;
  margin: 1rem auto;
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-button:hover {
  background-color: #3a7bc8;
}

.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}

.item-name {
  color: #FFF;
  font-size: 1.2rem;
  width: 50%; /* Adjust this value to give less space to item names */
  text-align: right;
}

.item-details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%; /* Adjust this value to balance with item-name */
}

.item-quantity, .item-price {
  color: #FFF;
  font-size: 1.2rem;
  margin: 0rem 0rem 0rem 1rem;
}

.item-quantity {
  margin-left: 6.5rem;
}

.order-total {
  margin: 1.5rem 0;
  text-align: center;
  font-size: 1.4rem;
  color: #007bff;
}

.paypal-button-container {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  background-color: #fef8f8;
}

.paypal-loading, .paypal-error {
  text-align: center;
  padding: 10px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 10px 0;
}

.paypal-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.form-message {
  color: #111;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}

@media (max-width: 1024px) {
  .checkout-page {
      flex-direction: column;
      padding: 1rem;
  }

  .checkout-form,
  .order-summary {
      width: 100%;
      margin-bottom: 1rem;
      margin-left: 0;
  }
}