
.whatsapp-icon {
    position: fixed;
    bottom: 15px;  /* Adjust this value to move it up or down */
    right: 20px;   /* Adjust this value to move it left or right */
    z-index: 1000; /* Ensures the icon stays on top of other elements */
  }
  
  .whatsapp-icon img {
    width: 60px;  /* Adjust size as needed */
    height: 60px;
  }
  
