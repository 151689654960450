/* Benefits Section Styling */
.benefits {
    padding: 40px 20px;
    background-color: #222; /* Dark background for the section */
    margin: 0 auto; /* Center the section */
    max-width: 1200px; /* Optional: Set a max-width for better alignment */
    text-align: center; /* Center text for the section */
}

.section-header {
    font-size: 2.5em; /* Increased font size for prominence */
    font-weight: bold;
    color: #FFFFFF; /* White color for the header text */
    margin-bottom: 30px; /* Increased margin for spacing */
    direction: rtl; /* Right-to-left direction for Hebrew text */
}

.benefits-container {
    display: flex;
    justify-content: space-around; /* Distribute items evenly */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
}

.benefit-item {
    width: 200px; /* Adjust width as needed */
    margin: 20px;
    text-align: center; /* Center text under icons */
    direction: rtl; /* Right-to-left direction for Hebrew text */
}

.benefit-icon {
    width: 200px; /* Adjust icon size for better visibility */
    height: auto;
    border-radius: 50%; /* Optional: Make icons round if desired */
}

.benefit-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 1.8em; /* Font size for benefit text */
    color: #FFF; /* White color for text */
    line-height: 1.5; /* Adjust line height for readability */
}

.benefit-line {
    margin: 2px 0;
}

/* Media Queries */
@media (max-width: 768px) {
    .benefits-container {
        flex-direction: column;
        align-items: center;
    }

    .benefit-item {
        width: 100%;
        max-width: 400px;
        margin-bottom: 30px;
    }

    .benefit-icon {
        width: 280px;
    }

    .benefit-text {
        font-size: 1.6em;
    }
}

@media (max-width: 480px) {
    .benefits-container {
        flex-direction: column; /* Stack items vertically on very small screens */
        align-items: center;
    }

    .benefit-item {
        width: auto; /* Allow item to take full width on small screens */
        max-width: 90%; /* Limit max width on small screens */
        margin-bottom: 20px; /* Add some space between stacked items */
    }

    .benefit-icon {
        width: 200px; /* Further reduce icon size on very small screens */
    }

    .benefit-text {
        font-size: 1em; /* Further reduce font size on very small screens */
    }
}