/* src/styles/BriefHowItWorks.css */
.brief-how-it-works {
    margin-top: 60px;
    padding: 20px;
    background-color: #222;
  }
  
  .brief-how-it-works-header {
    margin-bottom: 40px;
    font-size: 50px;
    color: #FFA500; /* Bright orange color to match the theme */
    text-align: center;
  }
  
  .brief-diagram {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .brief-diagram-item {
    margin: 0 20px;
    min-width: 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
  }
  
  .brief-diagram-item img {
    max-width: 170px;
    margin-bottom: 10px;
  }
  
  .brief-diagram-item p {
    margin: 5px 0;
    font-weight: bold;
  }
  
  .brief-arrow-icon {
    font-size: 60px;
    color: #67bbff;
    margin: 0 10px;
  }
  
  @media (max-width: 768px) {
    .brief-diagram {
      flex-direction: column;
    }
  
    .brief-diagram-item {
      margin: 20px 0;
    }
  
    .brief-arrow-icon {
      transform: rotate(270deg);
      margin: 10px 0;
    }
  }